import gql from 'graphql-tag'

export const FETCH_PROJECTS = gql`
  query fetchProjects(
    $page: Int
    $real_state_agency: String
    $project_id: [String]
  ) {
    projects(
      page: $page
      real_state_agency: $real_state_agency
      project_id: $project_id
    ) {
      data {
        id
        name
        type
        orden
        description
        quotation_validity
        reserve_validity
        projectstatus {
          id
          name
        }
        address
        latitude
        longitude
        type_property
        crm_id
        crm_zp
        crm_email
        price_from
        bedrooms_from
        bathrooms_from
        area_from
        deliver_date
        important
        legal_quote_text
        promises_type
        reserve_type
        reserve_currency
        reserve_amount
        summary
        chatbot_url
        exercise_area
        green_area
        bikers
        games_accessibility
        calisthenics_area
        stores
        events_room
        clean_points
        elevators
        controlled_access
        quincho
        swimming_pool
        reserve_crm
        reserve_crm_zp
        slug
        show_logo_realstate
        hospital
        subway_station
        show_section_news
        integration_crm
        promotions
        renta_minima
        video_youtube_id
        sales_room
        executivename {
          id
          email
        }
        executivephone {
          id
          email
        }
        executiveemail {
          id
          email
          contactphoto {
            id
            full_url
          }
        }
        commune {
          id
        }
        realstateagency {
          id
          name
        }
        subsidies {
          subsidy {
            id
            name
          }
        }
        projectstatus {
          id
          name
        }
        brochure {
          id
          full_url
        }
        nube360 {
          id
          full_url
        }
        tour360 {
          id
          full_url
        }
        visible
        audits {
          id
          event
          old_values
          new_values
          created_at
          user {
            name
            last_name
          }
        }
        projectcontactsquery {
          id
          email
        }
        projectcontactspromise {
          id
          email
        }
        projectcontactsquotation {
          id
          email
        }
        projectcontactsbooking {
          id
          email
        }
        projectcontactsdisengagement {
          id
          email
        }
        projectrestriction {
          id
          sell_subsidies
        }
        projectrelated {
          project_related_id
          project {
            id
            name
          }
        }
        medias {
          id
          full_url
          extension
          order
          mediatype {
            id
            name
          }
        }
        projectpaymentkeys {
          id
          api_key
          secret_key
        }
        planspayment {
          id
          name
        }
        projectadditional {
          plansadditional {
            id
            name
          }
        }
        projectstage {
          id
          name
        }
      }
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
    }
  }
`

export const CREATE_PROJECTS = gql`
  mutation createProjects(
    $name: String!
    $type: String!
    $description: String
    $reserve_validity: String
    $quotation_validity: String
    $project_status_id: Int
    $crm_id: String
    $crm_zp: String
    $crm_email: String
    $projects_related: [String]
    $price_from: String!
    $bedrooms_from: String!
    $bathrooms_from: String
    $area_from: String!
    $deliver_date: String
    $address: String
    $latitude: String
    $longitude: String
    $subsidy_type_id: [Int]
    $plans_additional_id: [Int]
    $commune_id: Int
    $type_property: String
    $brochurepdf: String
    $nube360: String
    $tour360: String
    $reserve_currency: String
    $reserve_type: String
    $reserve_amount: Int
    $executive_name: [String]
    $executive_phone: [String]
    $executive_email: [String]
    $executive_images: [String]
    $email_quotation_list: [String]
    $email_promise_list: [String]
    $email_booking_list: [String]
    $email_disengagement_list: [String]
    $logo: Upload
    $cover_page: Upload
    $gallery_photos: [Upload]
    $gallery_contact_photos: [Upload]
    $realstate_agency_id: Int
    $promises_type: String
    $legal_quote_text: String
    $summary: String
    $flow_api_key: String
    $flow_secret_key: String
    $orden: String
    $sell_subsidies: String
    $project_stage: String
    $chatbot_url: String
    $exercise_area: Boolean
    $green_area: Boolean
    $bikers: Boolean
    $games_accessibility: Boolean
    $calisthenics_area: Boolean
    $stores: Boolean
    $events_room: Boolean
    $clean_points: Boolean
    $elevators: Boolean
    $controlled_access: Boolean
    $quincho: Boolean
    $swimming_pool: Boolean
    $hospital: Boolean
    $subway_station: Boolean
  ) {
    createProject(
      name: $name
      type: $type
      description: $description
      reserve_validity: $reserve_validity
      quotation_validity: $quotation_validity
      project_status_id: $project_status_id
      crm_id: $crm_id
      crm_zp: $crm_zp
      crm_email: $crm_email
      projects_related: $projects_related
      price_from: $price_from
      bedrooms_from: $bedrooms_from
      bathrooms_from: $bathrooms_from
      area_from: $area_from
      deliver_date: $deliver_date
      address: $address
      latitude: $latitude
      longitude: $longitude
      subsidy_type_id: $subsidy_type_id
      plans_additional_id: $plans_additional_id
      commune_id: $commune_id
      type_property: $type_property
      brochurepdf: $brochurepdf
      nube360: $nube360
      tour360: $tour360
      reserve_currency: $reserve_currency
      reserve_amount: $reserve_amount
      reserve_type: $reserve_type
      executive_name: $executive_name
      executive_phone: $executive_phone
      executive_email: $executive_email
      executive_images: $executive_images
      email_quotation_list: $email_quotation_list
      email_promise_list: $email_promise_list
      email_booking_list: $email_booking_list
      email_disengagement_list: $email_disengagement_list
      logo: $logo
      cover_page: $cover_page
      gallery_photos: $gallery_photos
      gallery_contact_photos: $gallery_contact_photos
      realstate_agency_id: $realstate_agency_id
      promises_type: $promises_type
      legal_quote_text: $legal_quote_text
      summary: $summary
      flow_api_key: $flow_api_key
      flow_secret_key: $flow_secret_key
      orden: $orden
      sell_subsidies: $sell_subsidies
      project_stage: $project_stage
      chatbot_url: $chatbot_url
      exercise_area: $exercise_area
      green_area: $green_area
      bikers: $bikers
      games_accessibility: $games_accessibility
      calisthenics_area: $calisthenics_area
      stores: $stores
      events_room: $events_room
      clean_points: $clean_points
      elevators: $elevators
      controlled_access: $controlled_access
      quincho: $quincho
      swimming_pool: $swimming_pool
      hospital: $hospital
      subway_station: $subway_station
    ) {
      id
      name
      type
      orden
      description
      quotation_validity
      reserve_validity
      projectstatus {
        id
        name
      }
      address
      latitude
      longitude
      type_property
      crm_id
      crm_zp
      crm_email
      price_from
      bedrooms_from
      bathrooms_from
      area_from
      deliver_date
      important
      legal_quote_text
      promises_type
      reserve_type
      reserve_currency
      reserve_amount
      summary
      chatbot_url
      executivename {
        id
        email
      }
      executivephone {
        id
        email
      }
      executiveemail {
        id
        email
        contactphoto {
          full_url
          id
        }
      }
      commune {
        id
      }
      realstateagency {
        id
        name
      }
      subsidies {
        subsidy {
          id
          name
        }
      }
      projectstatus {
        id
        name
      }
      brochure {
        id
        full_url
      }
      nube360 {
        id
        full_url
      }
      tour360 {
        id
        full_url
      }
      visible
      audits {
        id
        event
        old_values
        new_values
        created_at
        user {
          name
          last_name
        }
      }
      projectcontactsquery {
        id
        email
      }
      projectcontactspromise {
        id
        email
      }
      projectcontactsquotation {
        id
        email
      }
      projectrestriction {
        id
        sell_subsidies
      }
      projectcontactsbooking {
        id
        email
      }
      projectcontactsdisengagement {
        id
        email
      }
      projectrelated {
        project_related_id
        project {
          id
          name
        }
      }
      medias {
        id
        full_url
        extension
        mediatype {
          id
          name
        }
      }
      projectpaymentkeys {
        id
        api_key
        secret_key
      }
      projectadditional {
        plansadditional {
          id
          name
        }
      }
      projectstage {
        id
        name
      }
    }
  }
`

export const EDIT_PROJECTS = gql`
  mutation editProjects(
    $project_id: Int!
    $name: String
    $type: String
    $description: String
    $reserve_validity: String
    $quotation_validity: String
    $project_status_id: Int
    $crm_id: String
    $crm_zp: String
    $crm_email: String
    $projects_related: [String!]
    $price_from: String!
    $bedrooms_from: String!
    $bathrooms_from: String
    $area_from: String!
    $deliver_date: String
    $address: String
    $latitude: String
    $longitude: String
    $subsidy_type_id: [Int]
    $plans_additional_id: [Int]
    $commune_id: Int
    $type_property: String
    $brochurepdf: String
    $nube360: String
    $tour360: String
    $executive_name: [String]
    $executive_phone: [String]
    $executive_email: [String]
    $email_quotation_list: [String]
    $email_promise_list: [String]
    $email_booking_list: [String]
    $email_disengagement_list: [String]
    $realstate_agency_id: String
    $reserve_currency: String
    $reserve_type: String
    $reserve_amount: Int
    $promises_type: String
    $legal_quote_text: String
    $summary: String
    $flow_api_key: String
    $flow_secret_key: String
    $orden: String
    $sell_subsidies: String
    $project_stage: String
    $chatbot_url: String
    $exercise_area: Boolean
    $green_area: Boolean
    $bikers: Boolean
    $games_accessibility: Boolean
    $calisthenics_area: Boolean
    $stores: Boolean
    $events_room: Boolean
    $clean_points: Boolean
    $elevators: Boolean
    $controlled_access: Boolean
    $quincho: Boolean
    $swimming_pool: Boolean
    $reserve_crm: Boolean
    $reserve_crm_zp: Boolean
    $show_logo_realstate: Boolean
    $hospital: Boolean
    $subway_station: Boolean
    $show_section_news: Boolean
    $integration_crm: String
    $promotions: String
    $renta_minima: Int
    $video_youtube_id: String
    $sales_room: String
  ) {
    editProject(
      project_id: $project_id
      name: $name
      type: $type
      orden: $orden
      description: $description
      reserve_validity: $reserve_validity
      quotation_validity: $quotation_validity
      project_status_id: $project_status_id
      crm_id: $crm_id
      crm_zp: $crm_zp
      crm_email: $crm_email
      projects_related: $projects_related
      price_from: $price_from
      bedrooms_from: $bedrooms_from
      bathrooms_from: $bathrooms_from
      area_from: $area_from
      deliver_date: $deliver_date
      address: $address
      latitude: $latitude
      longitude: $longitude
      subsidy_type_id: $subsidy_type_id
      plans_additional_id: $plans_additional_id
      commune_id: $commune_id
      type_property: $type_property
      brochurepdf: $brochurepdf
      nube360: $nube360
      tour360: $tour360
      executive_name: $executive_name
      executive_phone: $executive_phone
      executive_email: $executive_email
      email_quotation_list: $email_quotation_list
      email_promise_list: $email_promise_list
      email_booking_list: $email_booking_list
      email_disengagement_list: $email_disengagement_list
      realstate_agency_id: $realstate_agency_id
      reserve_currency: $reserve_currency
      reserve_type: $reserve_type
      reserve_amount: $reserve_amount
      promises_type: $promises_type
      legal_quote_text: $legal_quote_text
      summary: $summary
      flow_api_key: $flow_api_key
      flow_secret_key: $flow_secret_key
      sell_subsidies: $sell_subsidies
      project_stage: $project_stage
      chatbot_url: $chatbot_url
      exercise_area: $exercise_area
      green_area: $green_area
      bikers: $bikers
      games_accessibility: $games_accessibility
      calisthenics_area: $calisthenics_area
      stores: $stores
      events_room: $events_room
      clean_points: $clean_points
      elevators: $elevators
      controlled_access: $controlled_access
      quincho: $quincho
      swimming_pool: $swimming_pool
      reserve_crm: $reserve_crm
      reserve_crm_zp: $reserve_crm_zp
      show_logo_realstate: $show_logo_realstate
      hospital: $hospital
      subway_station: $subway_station
      show_section_news: $show_section_news
      integration_crm: $integration_crm
      promotions: $promotions
      renta_minima: $renta_minima
      video_youtube_id: $video_youtube_id
      sales_room: $sales_room
    ) {
      id
      name
      type
      orden
      description
      quotation_validity
      reserve_validity
      projectstatus {
        id
        name
      }
      address
      latitude
      longitude
      type_property
      crm_id
      crm_zp
      crm_email
      price_from
      bedrooms_from
      bathrooms_from
      area_from
      deliver_date
      important
      legal_quote_text
      promises_type
      reserve_type
      reserve_currency
      reserve_amount
      summary
      chatbot_url
      sales_room
      executivename {
        id
        email
      }
      executivephone {
        id
        email
      }
      executiveemail {
        id
        email
      }
      commune {
        id
      }
      realstateagency {
        id
        name
      }
      subsidies {
        subsidy {
          id
          name
        }
      }
      projectstatus {
        id
        name
      }
      projectrestriction {
        id
        sell_subsidies
      }
      brochure {
        id
        full_url
      }
      nube360 {
        id
        full_url
      }
      tour360 {
        id
        full_url
      }
      visible
      audits {
        id
        event
        old_values
        new_values
        created_at
        user {
          name
          last_name
        }
      }
      projectcontactsquery {
        id
        email
      }
      projectcontactspromise {
        id
        email
      }
      projectcontactsquotation {
        id
        email
      }
      projectcontactsbooking {
        id
        email
      }
      projectcontactsdisengagement {
        id
        email
      }
      projectrelated {
        project_related_id
        project {
          id
          name
        }
      }
      medias {
        id
        full_url
        extension
        mediatype {
          id
          name
        }
      }
      projectpaymentkeys {
        id
        api_key
        secret_key
      }
      projectadditional {
        plansadditional {
          id
          name
        }
      }
      projectstage {
        id
        name
      }
    }
  }
`

export const ADD_PROJECT_IMAGE = gql`
  mutation addProjectImage(
    $project_id: Int!
    $type: String!
    $files: [Upload]
  ) {
    addProjectImage(project_id: $project_id, type: $type, files: $files) {
      id
    }
  }
`

export const ImageInput = {
  id: String,
  order: String,
}

export const EDIT_ORDER_PROJECT_IMAGES = gql`
  mutation editOrderProjectImages(
    $images: [ImageInput]
  ) {
    editOrderProjectImages(images: $images) {
      id
    }
  }
`

export const ADD_PROJECT_IMAGE_CONTACT = gql`
  mutation addProjectImage(
    $project_id: Int!
    $type: String!
    $indexs: [String]
    $files: [Upload]
  ) {
    addProjectImage(
      project_id: $project_id
      type: $type
      indexs: $indexs
      files: $files
    ) {
      id
      executiveemail {
        id
        email
        contactphoto {
          full_url
          id
        }
      }
      executivename {
        id
        email
      }
      executivephone {
        id
        email
      }
    }
  }
`

export const REMOVE_PROJECT_IMAGE = gql`
  mutation removeProjectImage($project_id: Int!, $media_id: Int!) {
    removeProjectImage(project_id: $project_id, media_id: $media_id) {
      id
    }
  }
`

export const MODELS_BY_PROJECT = gql`
  query modelsbyproject($project_id: String!, $cache: String) {
    modelsbyproject(project_id: $project_id, cache: $cache) {
      models
      modelswithmedias {
        id
        model
        floormedias {
          id
          full_url
        }
      }
    }
  }
`

export const EDIT_MEDIA_PROPERTY_BY_MODEL = gql`
  mutation editMediaPropertyByModel(
    $project_id: String!
    $models: String!
    $file: Upload
  ) {
    editMediaPropertyByModel(
      project_id: $project_id
      models: $models
      file: $file
    ) {
      models
    }
  }
`

export const DELETE_MEDIA_PROJECT = gql`
  mutation deleteMediaProject($project_id: Int!, $media_id: Int) {
    deleteMediaProject(project_id: $project_id, media_id: $media_id) {
      id
    }
  }
`

export const DELETE_PROJECTS = gql`
  mutation removeProject($id: Int!) {
    removeProject(project_id: $id) {
      id
    }
  }
`

export const CHECK_PROPERTIES_EXCEL = gql`
  mutation checkPropertiesExcel($excel: Upload) {
    checkPropertiesExcel(excel: $excel) {
      status
      info
      data
      validation_errors
    }
  }
`

export const SAVE_PROPERTIES_EXCEL = gql`
  mutation registerPropertiesSave($project_id: Int!, $jsonExcel: String!) {
    registerPropertiesSave(project_id: $project_id, jsonExcel: $jsonExcel) {
      status
      info
      data
      validation_errors
    }
  }
`

export const CHANGE_VISIBLE = gql`
  mutation editVisibleProject($visible: Boolean!, $project_id: Int!) {
    editVisibleProject(visible: $visible, project_id: $project_id) {
      visible
    }
  }
`

export const CHANGE_PLAN = gql`
  mutation editPlanProject($plan: String!, $project_id: Int!) {
    editPlanProject(plan: $plan, project_id: $project_id) {
      status
      info
      project {
        id
        name
        planspayment {
          id
          name
        }
      }
    }
  }
`

export const EDIT_PROJECT_IMPORTANT = gql`
  mutation editProjectImportant($project_id: Int!, $important: String) {
    editProjectImportant(project_id: $project_id, important: $important) {
      important
    }
  }
`

export const CREATE_WEBHOOKS = gql`
  mutation createWebhooks(
    $name: String!
    $description: String!
    $event_type: String!
    $url: String!
    $project_id: Int!
  ) {
    createWebhook(
      name: $name
      description: $description
      event_type: $event_type
      url: $url
      project_id: $project_id
    ) {
      id
      name
      event_type
      url
      project_id
    }
  }
`

export const FETCH_WEBHOOKS = gql`
  query fetchWebhooks($project_id: Int) {
    webhooks(project_id: $project_id) {
      id
      project_id
      url
      description
      event_type
      name
      audits {
        id
        event
        old_values
        new_values
        created_at
        user {
          name
          last_name
        }
      }
    }
  }
`

export const FETCH_WEBHOOKS_RECORDS = gql`
  query fetchWebhooksRecords($project_id: Int) {
    webhooksrecords(project_id: $project_id) {
      id
      status_code
      message
      response_body
      request_body
      created_at
      webhook {
        id
        project_id
        url
        description
        event_type
        name
      }
    }
  }
`

export const DELETE_WEBHOOKS = gql`
  mutation deleteWebhook($id: Int!) {
    deleteWebhook(id: $id) {
      id
    }
  }
`

export const EDIT_WEBHOOKS = gql`
  mutation editWebhooks(
    $webhook_id: Int!
    $description: String
    $url: String
    $event_type: String
    $name: String
  ) {
    editWebhook(
      webhook_id: $webhook_id
      description: $description
      url: $url
      event_type: $event_type
      name: $name
    ) {
      id
      description
      url
      event_type
      name
    }
  }
`

export const FETCH_MEDIA_PROJECTS = gql`
  query fetchMediaProjects(
    $project_id: Int!
  ) {
    projectsmedia(
      project_id: $project_id
    ) {
      id
      full_url
      extension
      order
      mediatype {
        id
        name
      }
    }
  }
`