import Vue from 'vue'
import store from '@/store'
import graphqlClient from '@/plugins/apollo'
import {
  CHANGE_VISIBLE_PROPERTY,
  CHANGE_VISIBLE_WAREHOUSE,
  CHANGE_VISIBLE_PARKINGLOT,
  FETCH_PROPERTIES,
  FETCH_WAREHOUSES,
  FETCH_PARKINGLOTS,
  PROPERTIES_EXTRA_DATA,
  DELETE_PROPERTIES,
  EDIT_PROPERTY_VISIBLE,
  EDIT_PROPERTY_BOOKABLE,
  EDIT_PROPERTY_PROMISABLE,
  EDIT_PARLINGLOT_VISIBLE,
  EDIT_PARLINGLOT_BOOKABLE,
  EDIT_PARLINGLOT_PROMISABLE,
  EDIT_WAREHOUSE_VISIBLE,
  EDIT_WAREHOUSE_BOOKABLE,
  EDIT_WAREHOUSE_PROMISABLE,
  CHANGE_VISIBLE_GROUPED_PROPERTY,
  CHANGE_VULNERABLE_GROUPED_PROPERTY,
  CHANGE_SECTORMEDIO_GROUPED_PROPERTY,
  EDIT_PROPERTY_PRICE,
  UPDATE_PROPERTIES_EXCEL,
  FETCH_PROPERTIES_FROM_HOME,
  EDIT_PROPERTY_ALL
} from '@/services/graphql/properties'

export async function fetchProperties(context, filter) {
  return new Promise((resolve, reject) => {
    if (filter.project != '' && filter.project != null) {
      var project = filter.project
      var identifier = filter.identifier != '' ? filter.identifier : undefined
      var model = filter.model != null ? filter.model : undefined
      var orientation =
        filter.orientation != null ? filter.orientation : undefined
      var tower = filter.tower != null ? filter.tower : undefined
      var visible = filter.visible != null ? filter.visible : undefined
    }
    graphqlClient
      .query({
        query: FETCH_PROPERTIES,
        variables: {
          project_id: project ? project.toString() : project,
          identifier: identifier,
          page: filter.page,
          model: model,
          orientation: orientation,
          tower: tower,
          visible: visible
        }
      })
      .then(res => {
        context.commit('SET_PROPERTIES', res.data.properties)
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function fetchPropertiesFromHome(context, filter) {
  return new Promise((resolve, reject) => {
    if (filter.project != '' && filter.project != null) {
      var project = filter.project
    }
    graphqlClient
      .query({
        query: FETCH_PROPERTIES_FROM_HOME,
        variables: {
          project_id: project,
          page: filter.page,
          real_state_agency: filter.real_state_agency.toString()
        }
      })
      .then(res => {
        context.commit('SET_PROPERTIES_FROM_HOME', res.data.tablepropertie)
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function fetchParkinglots(context, filter) {
  return new Promise((resolve, reject) => {
    if (filter.project != '' && filter.project != null) {
      var project = filter.project
      var identifier = filter.identifier != '' ? filter.identifier : undefined
      var model = filter.model != null ? filter.model : undefined
      var orientation =
        filter.orientation != null ? filter.orientation : undefined
      var tower = filter.tower != null ? filter.tower : undefined
      var visible = filter.visible != null ? filter.visible : undefined
    }
    graphqlClient
      .query({
        query: FETCH_PARKINGLOTS,
        variables: {
          project_id: project,
          identifier: identifier,
          page: filter.page,
          model: model,
          orientation: orientation,
          tower: tower,
          visible: visible
        }
      })
      .then(res => {
        context.commit('SET_PARKINGLOTS', res.data.parkinglots)
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function fetchWarehouses(context, filter) {
  return new Promise((resolve, reject) => {
    if (filter.project != '' && filter.project != null) {
      var project = filter.project
      var identifier = filter.identifier != '' ? filter.identifier : undefined
      var model = filter.model != null ? filter.model : undefined
      var orientation =
        filter.orientation != null ? filter.orientation : undefined
      var tower = filter.tower != null ? filter.tower : undefined
      var visible = filter.visible != null ? filter.visible : undefined
    }
    graphqlClient
      .query({
        query: FETCH_WAREHOUSES,
        variables: {
          project_id: project,
          identifier: identifier,
          page: filter.page,
          model: model,
          orientation: orientation,
          tower: tower,
          visible: visible
        }
      })
      .then(res => {
        context.commit('SET_WAREHOUSES', res.data.warehouses)
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function propertiesExtraData(context, project) {
  return new Promise((resolve, reject) => {
    graphqlClient
      .query({
        query: PROPERTIES_EXTRA_DATA,
        variables: {
          project_id: project.toString()
        }
      })
      .then(res => {
        context.commit(
          'SET_PROPERTIES_EXTRA_DATA',
          res.data.propertiesandmodelsbyproject
        )
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function changeVisibleGroupedProperties(context, filter) {
  return new Promise((resolve, reject) => {
    let properties = filter.properties.map(e => (e = e.toString()))
    graphqlClient
      .mutate({
        mutation: CHANGE_VISIBLE_GROUPED_PROPERTY,
        variables: {
          propertie_type: filter.propertie_type,
          properties: properties,
          visible: filter.visible
        }
      })
      .then(res => {
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })
}
export function changeVulnerableGroupedProperties(context, filter) {
  return new Promise((resolve, reject) => {
    let properties = filter.properties.map(e => (e = e.toString()))
    graphqlClient
      .mutate({
        mutation: CHANGE_VULNERABLE_GROUPED_PROPERTY,
        variables: {
          propertie_type: filter.propertie_type,
          properties: properties,
          vulnerable: filter.vulnerable
        }
      })
      .then(res => {
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })
}
export function changeSectorMedioGroupedProperties(context, filter) {
  return new Promise((resolve, reject) => {
    let properties = filter.properties.map(e => (e = e.toString()))
    graphqlClient
      .mutate({
        mutation: CHANGE_SECTORMEDIO_GROUPED_PROPERTY,
        variables: {
          propertie_type: filter.propertie_type,
          properties: properties,
          sector_medio: filter.sector_medio
        }
      })
      .then(res => {
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function exportProperties(context, params) {
  return new Promise((resolve, reject) => {
    Vue.axios({
      params: params,
      method: 'GET',
      responseType: 'blob',
      url: 'api/excelProperties',
      headers: { Authorization: `Bearer ${store.state.auth.accessToken}` }
    })
      .then(res => {
        let blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
        let url = window.URL.createObjectURL(blob)
        let a = document.createElement('a')
        a.href = url
        a.download = 'Unidades.xlsx'
        a.click()
        window.URL.revokeObjectURL(url)
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function exportWarehousesOrParkingLots(context, params) {
  return new Promise((resolve, reject) => {
    Vue.axios({
      params: params,
      method: 'GET',
      responseType: 'blob',
      url: 'api/excelWarehousesOrParkingLots',
      headers: { Authorization: `Bearer ${store.state.auth.accessToken}` }
    })
      .then(res => {
        let blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
        let url = window.URL.createObjectURL(blob)
        let a = document.createElement('a')
        a.href = url
        a.download = 'Unidades.xlsx'
        a.click()
        window.URL.revokeObjectURL(url)
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function deleteProperties(context, project) {
  return new Promise((resolve, reject) => {
    graphqlClient
      .mutate({
        mutation: DELETE_PROPERTIES,
        variables: {
          id: propertie.id
        },
        update: (store, { data: { DELETE_PROPERTIES } }) => {
          const data = store.readQuery({
            query: FETCH_PROPERTIES,
            variables: { page: 1 }
          })
          data.properties.data.forEach(function (value, index, object) {
            if (value.id === property.id) {
              object.splice(index, 1)
            }
          })
          store.writeQuery({ query: FETCH_PROPERTIES, data })
        }
      })
      .then(res => {
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function changeVisibleWarehouse(context, warehouse) {
  return new Promise((resolve, reject) => {
    graphqlClient
      .mutate({
        mutation: CHANGE_VISIBLE_WAREHOUSE,
        variables: {
          visible: warehouse.visible,
          warehouse_id: warehouse.data_id
        },
        update: (store, { data: { CHANGE_VISIBLE_WAREHOUSE } }) => {
          const data = store.readQuery({
            query: FETCH_WAREHOUSES,
            variables: { page: 1 }
          })
          context.commit('SET_WAREHOUSES', data)
          store.writeQuery({ query: FETCH_WAREHOUSES, data })
        }
      })
      .then(res => {
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function changeVisibleParkinglot(context, parkinglot) {
  return new Promise((resolve, reject) => {
    graphqlClient
      .mutate({
        mutation: CHANGE_VISIBLE_PARKINGLOT,
        variables: {
          visible: parkinglot.visible,
          parkinglot_id: parkinglot.data_id
        },
        update: (store, { data: { CHANGE_VISIBLE_PARKINGLOT } }) => {
          const data = store.readQuery({
            query: FETCH_PARKINGLOTS,
            variables: { page: 1 }
          })
          context.commit('SET_PARKINGLOTS', data)
          store.writeQuery({ query: FETCH_PARKINGLOTS, data })
        }
      })
      .then(res => {
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function changePropertyVisible(context, property) {
  return new Promise((resolve, reject) => {
    graphqlClient
      .mutate({
        mutation: EDIT_PROPERTY_VISIBLE,
        context: {},
        variables: {
          property_id: property.id,
          visible: property.visible
        },
        update: (store, { data: { CHANGE_VISIBLE_PROPERTY } }) => {
          // const data = store.readQuery({query: FETCH_PROPERTIES, variables: {page: 1}})
          // context.commit('SET_PROPERTIES',data)
          // store.writeQuery({query: FETCH_PROPERTIES, data})
        }
      })
      .then(res => {
        context.commit('UPDATE_AUDITS_PROPERTY', res)
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function updatePropertiesExcel(context, data) {
  var variables = {
    excel: data.excel,
    project_id: data.project_id
  }
  return new Promise((resolve, reject) => {
    graphqlClient
      .mutate({
        mutation: UPDATE_PROPERTIES_EXCEL,
        context: {
          hasUpload: true // Important!
        },
        variables: variables
      })
      .then(res => {
        context.commit('UPDATE_PROPERTIES_FROM_EXCEL', res)
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function changePropertyPrice(context, property) {
  return new Promise((resolve, reject) => {
    graphqlClient
      .mutate({
        mutation: EDIT_PROPERTY_PRICE,
        context: {},
        variables: {
          property_id: property.id,
          price: property.price
        },
        update: (store, { data: { CHANGE_VISIBLE_PROPERTY } }) => {
          // const data = store.readQuery({query: FETCH_PROPERTIES, variables: {page: 1}})
          // context.commit('SET_PROPERTIES',data)
          // store.writeQuery({query: FETCH_PROPERTIES, data})
        }
      })
      .then(res => {
        context.commit('UPDATE_AUDITS_PROPERTY', res)
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function changeVisibleProperty(context, property) {
  return new Promise((resolve, reject) => {
    graphqlClient
      .mutate({
        mutation: CHANGE_VISIBLE_PROPERTY,
        variables: {
          visible: property.visible,
          property_id: property.id
        },
        update: (store, { data: { CHANGE_VISIBLE_PROPERTY } }) => {
          // const data = store.readQuery({query: FETCH_PROPERTIES, variables: {page: 1}})
          // context.commit('SET_PROPERTIES',data)
          // store.writeQuery({query: FETCH_PROPERTIES, data})
          context.commit('UPDATE_AUDITS_PROPERTY_VISIBLE', data)
        }
      })
      .then(res => {
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function changePropertyBookable(context, property) {
  return new Promise((resolve, reject) => {
    graphqlClient
      .mutate({
        mutation: EDIT_PROPERTY_BOOKABLE,
        context: {},
        variables: {
          property_id: property.id,
          bookeable: property.bookeable
        },
        update: (store, { data: { CHANGE_VISIBLE_PROPERTY } }) => {
          const data = store.readQuery({
            query: FETCH_PROPERTIES,
            variables: { page: 1 }
          })
          context.commit('SET_PROPERTIES', data)
          store.writeQuery({ query: FETCH_PROPERTIES, data })
        }
      })
      .then(res => {
        context.commit('UPDATE_AUDITS_PROPERTY', res)
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function changePropertyPromisable(context, property) {
  return new Promise((resolve, reject) => {
    graphqlClient
      .mutate({
        mutation: EDIT_PROPERTY_PROMISABLE,
        context: {},
        variables: {
          property_id: property.id,
          promisable: property.promisable
        },
        update: (store, { data: { CHANGE_VISIBLE_PROPERTY } }) => {
          const data = store.readQuery({
            query: FETCH_PROPERTIES,
            variables: { page: 1 }
          })
          context.commit('SET_PROPERTIES', data)
          store.writeQuery({ query: FETCH_PROPERTIES, data })
        }
      })
      .then(res => {
        context.commit('UPDATE_AUDITS_PROPERTY', res)
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })
}

//parkinglots
export async function changeParkinglotVisible(context, property) {
  return new Promise((resolve, reject) => {
    graphqlClient
      .mutate({
        mutation: EDIT_PARLINGLOT_VISIBLE,
        context: {},
        variables: {
          parkinglot_id: property.id,
          visible: property.visible
        },
        update: (store, { data: { CHANGE_VISIBLE_PARKINGLOT } }) => {
          // const data = store.readQuery({query: FETCH_PARKINGLOTS, variables: {page: 1}})
          // context.commit('SET_PARKINGLOTS',data)
          // store.writeQuery({query: FETCH_PARKINGLOTS, data})
        }
      })
      .then(res => {
        context.commit('UPDATE_AUDITS_PARKINGLOT', res)
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function changeParkinglotBookable(context, property) {
  return new Promise((resolve, reject) => {
    graphqlClient
      .mutate({
        mutation: EDIT_PARLINGLOT_BOOKABLE,
        context: {},
        variables: {
          parkinglot_id: property.id,
          bookeable: property.bookeable
        },
        update: (store, { data: { CHANGE_VISIBLE_PARKINGLOT } }) => {
          const data = store.readQuery({
            query: FETCH_PARKINGLOTS,
            variables: { page: 1 }
          })
          context.commit('SET_PARKINGLOTS', data)
          store.writeQuery({ query: FETCH_PARKINGLOTS, data })
        }
      })
      .then(res => {
        context.commit('UPDATE_AUDITS_PARKINGLOT', res)
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function changeParkinglotPromisable(context, property) {
  return new Promise((resolve, reject) => {
    graphqlClient
      .mutate({
        mutation: EDIT_PARLINGLOT_PROMISABLE,
        context: {},
        variables: {
          parkinglot_id: property.id,
          promisable: property.promisable
        },
        update: (store, { data: { CHANGE_VISIBLE_PARKINGLOT } }) => {
          const data = store.readQuery({
            query: FETCH_PARKINGLOTS,
            variables: { page: 1 }
          })
          context.commit('SET_PARKINGLOTS', data)
          store.writeQuery({ query: FETCH_PARKINGLOTS, data })
        }
      })
      .then(res => {
        context.commit('UPDATE_AUDITS_PARKINGLOT', res)
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })
}

//warehouses
export async function changeWarehouseVisible(context, property) {
  return new Promise((resolve, reject) => {
    graphqlClient
      .mutate({
        mutation: EDIT_WAREHOUSE_VISIBLE,
        context: {},
        variables: {
          warehouse_id: property.id,
          visible: property.visible
        },
        update: (store, { data: { CHANGE_VISIBLE_WAREHOUSE } }) => {
          // const data = store.readQuery({query: FETCH_WAREHOUSES, variables: {page: 1}})
          // context.commit('SET_WAREHOUSES',data)
          // store.writeQuery({query: FETCH_WAREHOUSES, data})
        }
      })
      .then(res => {
        context.commit('UPDATE_AUDITS_WAREHOUSE', res)
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function changeWarehouseBookable(context, property) {
  return new Promise((resolve, reject) => {
    graphqlClient
      .mutate({
        mutation: EDIT_WAREHOUSE_BOOKABLE,
        context: {},
        variables: {
          warehouse_id: property.id,
          bookeable: property.bookeable
        },
        update: (store, { data: { CHANGE_VISIBLE_WAREHOUSE } }) => {
          const data = store.readQuery({
            query: FETCH_WAREHOUSES,
            variables: { page: 1 }
          })
          context.commit('SET_WAREHOUSES', data)
          store.writeQuery({ query: FETCH_WAREHOUSES, data })
        }
      })
      .then(res => {
        context.commit('UPDATE_AUDITS_WAREHOUSE', res)
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function changeWarehousePromisable(context, property) {
  return new Promise((resolve, reject) => {
    graphqlClient
      .mutate({
        mutation: EDIT_WAREHOUSE_PROMISABLE,
        context: {},
        variables: {
          warehouse_id: property.id,
          promisable: property.promisable
        },
        update: (store, { data: { CHANGE_VISIBLE_WAREHOUSE } }) => {
          const data = store.readQuery({
            query: FETCH_WAREHOUSES,
            variables: { page: 1 }
          })
          context.commit('SET_WAREHOUSES', data)
          store.writeQuery({ query: FETCH_WAREHOUSES, data })
        }
      })
      .then(res => {
        context.commit('UPDATE_AUDITS_WAREHOUSE', res)
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function editPropertyAll(context, property) {
  return new Promise((resolve, reject) => {
    graphqlClient
      .mutate({
        mutation: EDIT_PROPERTY_ALL,
        variables: {
          property_id: property.property_id,
          identifier: property.identifier,
          model: property.model,
          tower: property.tower,
          lots: property.lots,
          total_area: +property.total_area,
          municipal_area: +property.municipal_area,
          terrace_area: +property.terrace_area,
          built_area: +property.built_area,
          floor: +property.floor,
          bedrooms: +property.bedrooms,
          bathrooms: property.bathrooms.toString(),
          vulnerable: property.vulnerable,
          price: +property.price,
          id_crm_movendo:
            property.id_crm_movendo && property.id_crm_movendo.toString(),
          base_subsidies: property.base_subsidies != null && property.base_subsidies != '' ? +property.base_subsidies : null,
          savings: property.savings != null && property.savings != '' ? +property.savings : null,
          bonus_integration: property.bonus_integration != null && property.bonus_integration != '' ? +property.bonus_integration : null,
          bonus_recruitment: property.bonus_recruitment != null && property.bonus_recruitment != '' ? +property.bonus_recruitment : null,
          show_price_from: property.show_price_from,
          base_subsidie_ds15: property.base_subsidie_ds15 != null && property.base_subsidie_ds15 != '' ? +property.base_subsidie_ds15 : null,
          saving_ds15: property.saving_ds15 != null && property.saving_ds15 != '' ? +property.saving_ds15 : null,
        },
        update: (store, { data: { CHANGE_PROPERTY } }) => {
          const data = store.readQuery({
            query: FETCH_PROPERTIES,
            variables: { page: 1 }
          })
          context.commit('SET_PROPERTIES', data)
          store.writeQuery({ query: FETCH_PROPERTIES, data })
        }
      })
      .then(res => {
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function projectProperty(context, project_id) {
  context.commit('SET_PROPERTY_PROJECT', project_id)
}

export async function projectSelect(context, data) {
  context.commit('SET_PROPERTY_SELECT', data)
}
